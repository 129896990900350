// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-building-guide-js": () => import("./../../../src/pages/building-guide.js" /* webpackChunkName: "component---src-pages-building-guide-js" */),
  "component---src-pages-certified-buildings-js": () => import("./../../../src/pages/certified-buildings.js" /* webpackChunkName: "component---src-pages-certified-buildings-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-financing-js": () => import("./../../../src/pages/financing.js" /* webpackChunkName: "component---src-pages-financing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdon-content-js": () => import("./../../../src/pages/markdon-content.js" /* webpackChunkName: "component---src-pages-markdon-content-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rent-to-own-js": () => import("./../../../src/pages/rent-to-own.js" /* webpackChunkName: "component---src-pages-rent-to-own-js" */),
  "component---src-pages-roof-styles-js": () => import("./../../../src/pages/roof-styles.js" /* webpackChunkName: "component---src-pages-roof-styles-js" */),
  "component---src-pages-site-preparation-js": () => import("./../../../src/pages/site-preparation.js" /* webpackChunkName: "component---src-pages-site-preparation-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-main-category-js": () => import("./../../../src/templates/mainCategory.js" /* webpackChunkName: "component---src-templates-main-category-js" */),
  "component---src-templates-metal-buildings-for-sale-js": () => import("./../../../src/templates/metalBuildingsForSale.js" /* webpackChunkName: "component---src-templates-metal-buildings-for-sale-js" */),
  "component---src-templates-sub-category-js": () => import("./../../../src/templates/subCategory.js" /* webpackChunkName: "component---src-templates-sub-category-js" */)
}

